import Step from "../../components/step/Step";
import {Box, SimpleGrid, Text, useRadioGroup} from "@chakra-ui/react";
import RadioCard from "../../components/radio-card/RadioCard";
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import maleBody1 from "../../assets/step2/male-body-1.png";
import maleBody2 from "../../assets/step2/male-body-2.png";
import maleBody3 from "../../assets/step2/male-body-3.png";
import maleBody4 from "../../assets/step2/male-body-4.png";
import femaleBody1 from "../../assets/step2/female-body-1.png";
import femaleBody2 from "../../assets/step2/female-body-2.png";
import femaleBody3 from "../../assets/step2/female-body-3.png";
import femaleBody4 from "../../assets/step2/female-body-4.png";
import {useIntl} from "react-intl";

function Step2() {
    let navigate = useNavigate();
    const intl = useIntl();

    const {getRootProps, getRadioProps} = useRadioGroup({
        name: 'q2',
        onChange: () => {
            navigate('../step3', {replace: true});
        }
    });

    const state = useStore.getState();

    const options = [
        {
            'id': 'a1',
            'icon': state.gender === 'male' ? maleBody1 : femaleBody1,
            'text': intl.formatMessage({id: 'step2.option1'})
        },
        {
            'id': 'a2',
            'icon': state.gender === 'male' ? maleBody2 : femaleBody2,
            'text': intl.formatMessage({id: 'step2.option2'})
        },
        {
            'id': 'a3',
            'icon': state.gender === 'male' ? maleBody3 : femaleBody3,
            'text': intl.formatMessage({id: 'step2.option3'})
        },
        {
            'id': 'a4',
            'icon': state.gender === 'male' ? maleBody4 : femaleBody4,
            'text': intl.formatMessage({id: 'step2.option4'})
        }
    ];

    const group = getRootProps()

    return (
        <Step heading={intl.formatMessage({id: 'step2.headline'})}
              currentStep={1}
              backLink={'/'}>

            <SimpleGrid mt={5} spacing={2} columns={1} {...group}>
                {options.map((value) => {
                    const radio = getRadioProps({'value': value.id})
                    return (
                        <RadioCard key={value.id} {...radio}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box mr={5}>
                                    <img height={100} width={100} src={value.icon} alt=''/>
                                </Box>
                                <Text fontSize={'lg'} fontWeight={'bold'} mt={3}>{value.text}</Text>
                            </Box>
                        </RadioCard>
                    )
                })}
            </SimpleGrid>
        </Step>
    );
}

export default Step2;
